import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../assets/Thankyou/css/Thankyou.scss";
import logoWhite from "../../assets/img/S_GYR_V1/logo-white.png";
import SRA from "../../assets/img/S_GYR_V1/SRA.png";
import Footer from '../Includes/Layouts/S_GYR_V1/Footer';
const Thankyou = () => {
  localStorage.clear();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const modalClick = (clickValue) => {
    switch (clickValue) {

      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showCookie": {
        setShowCookie(true);
        break;
      }
      case "exitCookie": {
        setShowCookie(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
    }
  };
  return (
    <>
      <div className="__GYRhome thankyou_page">
        <header>
          <div className="container top_notch">
            <div className="row">
              <div className="col-12 nav_barACA">
                <div className="img_slot">
                  <img src={logoWhite} alt="" />
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="cs_hero cs_style_1 cs_foot_style pt-75">
          <div className="">
            <div className="container">
              <div className="row pb-5 pad_none justify-content-center">
                <div className="cs_hero_text col-lg-8 col-12 text-center text-color">

                  <div className="main-container pt-3">
                    <div className="check-container">
                      <div className="check-background">
                        <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      <div className="check-shadow"></div>
                    </div>
                  </div>
                  <h1 className="cs_hero_title text-center animate__animated animate__fadeInUp animate__delay-1s">We've got your details</h1>
                  <h4 className="cs_hero_title text-center animate__animated animate__fadeInUp animate__delay-1s text-font">Thank you for completing our claim enquiry form.</h4>
                  <p className="w-100 lit_sm animate__animated animate__fadeInUp animate__delay-.5s" style={{ maxWidth: "unset !important" }}>
                    <b>
                    If you have any questions about the process, you can contact us by email at support@getyourrefund.co.uk or call us on 01495 654360.
                    </b>
                  </p>
                  <p className="w-100 lit_sm animate__animated animate__fadeInUp animate__delay-.5s text-start" style={{ maxWidth: "unset !important" }}>
                    <b>
                    Our team will now review the information you have provided. Within one working day, we’ll contact you to finalise your enquiry and ask you to:
                    </b>
                  </p>
                  <ul className='text-start'>
                    <li className='text-start'>
                    Complete an online ID check – this ensures we are representing the correct person and will allow us to obtain information about hidden or discretionary commissions that may have affected your finance agreement(s).
                    </li>
                    <li className='text-start'>
                    Get Your Refund is a trading style of Stephens Wilmot Limited – you will see the name ‘Stephens Wilmot Solicitors’ on some of the documents we ask you to sign.
                    </li>
                  </ul>
                  <p className='text-start'>We will contact you by email and text throughout the process, so please watch for our updates. Please also remember to check your junk folder when looking out for our emails.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer/>
      </div>
    </>
  )
}

export default Thankyou;
