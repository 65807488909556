import React, { useContext, useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import ProgressBar from "./ProgressBar";

const RadioQuestionnaire = (props) => {
  const [checked, setChecked] = useState();
  const updateChecked = (e) => {
    setChecked(e.target.value);
    props.slideChange(e)
  }
  const Radio = props.gtm_question.answers.map((value, index) => {
    return (
      <li className="choice-1 depth-1" key={index}>
        <RadioButton
          id={`${value.label}_${props.gtm_question.questionId}`}
          value={value.value}
          name={`question_${props.gtm_question.questionId}`}
          className="radio radio-2"
          labelName={value.label}
          labelClassName={`wpforms-field-label-inline qstn-label mb-0 ${checked == value.value ? 'current-checked' : ''}`}
          onChange={updateChecked}
          onClick={updateChecked}
          validation={props.validation()}
          gtm_question={props.gtm_question.gtmQuestion}
          gtm_trigger={props.gtm_question.gtm_trigger}
          gtm_answer={``}
          clickevent={props.gtm_question.clickevent}
          checked={checked == value.value ? true : false}
        />
      </li>
    )
  });
  return (
    <>
      <div className={`form-view animate__animated animate__fadeInUp animate__delay-.5s" id="step-2 ${props.className}`}>
        <ProgressBar step={props.step} load={props.loadPercent} />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s animate__animated animate__fadeInUp animate__delay-.5s">
          <label>{props.gtm_question.question}<em>*</em><span>{props.gtm_question.subQuestion}</span>
          </label>
          <ul id="wpforms-1545-field_130" className="wpforms-field-required">
            {Radio}
          </ul>
        </div>
      </div>
    </>

  );
};
export default RadioQuestionnaire;
