import context from "react-bootstrap/esm/AccordionContext";

export const SplitFaq_S_GYR_V1 = [
    {
        heading: "What is PCP?",
        content: "<p>Personal contract purchases (PCP) are a very popular way of financing a car, also known as personal contract hire. You typically pay a deposit, and then a monthly fee for the car for a set number of years. At the end of the deal, you can usually hand the car back for no extra cost, pay a lump sum to enable you to keep the car, or put the value towards a different car with the same dealership.</p>"
    },
    {
        heading: "What is a hidden commission?",
        content: "<p>Hidden commission is when you are sold a vehicle on a PCP or Hire Purchase finance agreement, but you are either not told that the dealer will be making commission, or they do not tell you the extent of the commission.</p><p>Many people purchasing a car before January 2021 were not made aware that the dealer would also earn more commission for selling them the finance product (the PCP or Hire Purchase agreement), on top of any commission they make for selling the car itself.</p><p>Dealers should always be transparent about the amount of commission they are earning, to help ensure a transparent sale and reduce the risk of the dealer offering finance products based on commission and not what’s best for their customer.</p><p>If you were sold a vehicle on a PCP or Hire Purchase agreement and the dealer did not make their commission clear to you, this may be classed as hidden commission and you may have a mis-sold car finance claim.</p>"
    },
    {
        heading: "What is the mis-sold car finance average payout?",
        content: '<p>The payout will depend on your specific circumstances and how much you paid that you shouldn’t have done. However, many people may be due back thousands of pounds. So, if you believe you have been affected, it is worthwhile to investigate, which our expert solicitors can assist you with.</p>'
    },
    {
        heading: "What is Hire Purchase?",
        content: '<p>Hire Purchase is another way to finance a car. You’ll typically pay a deposit, then pay a set payment each month until you have paid off the value of the car. The loan is secured against the car, so you don’t own it until you’ve made all of the payments.</p>'
    },
    {
        heading: "How long will it take to claim for mis-sold car finance?",
        content: '<p>There is no set time period to claim for mis sold car finance loans. If the finance provider accepts responsibility straight away and agrees to pay you back the money you’re owed, it could take a matter of months. However, if the finance provider disputes the claim, it could take much longer.  The final decision on your claim will likely be linked to the FCA’s conclusion of their investigation into the industry, but it is important that you lodge your claim as soon as possible to join the queue. One of our expert solicitors will be able to assist you with this.</p>'
    },
    {
        heading: "How much do mis-sold car finance claims cost?",
        content: "<p>We work on a ‘no win no fee’ basis, meaning there is no charge if your claim is unsuccessful. If your claim is successful, our fee is 25% plus VAT of any compensation amount. Our expert team will manage the whole process on your behalf, and investigate whether you're owed a refund due to mis-sold car finance.</p>"
    },
    {
        heading:"Will I be charged if my claim is not successful?",
        content:"<p>Our No Win, No Fee service means we will only charge a fee if your claim is successful (equal to 25% + VAT of your compensation) or if you cancel your claim after the 14-day cooling-off period expires but before your claim has concluded, in which case we may charge you a fee for any reasonable costs we have incurred at the rate of £175 per hour (including VAT).</p>"
    }
];