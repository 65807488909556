import React from "react";

const ProgressBar = (props) => {
  return (
    <>
      <div className="progress_bar">
        <p className="p-0 mb-2 text-white fw-semibold ">Step {props.step} of 5</p>
        <div className="progress-bar-line">
          <span className={`${props.load}`}></span>
        </div>
      </div>
    </>
  )
}

export default ProgressBar;