import React from "react";
import GTMDataLayer from "../../Utility/GTMDataLayer";

const SelectBox = ({
  className,
  OptionValue,
  onChange,
  name,
  id,
  myRef,
  validationMsg,
  placeholderOption,
  value,
  clickevent,
  gtm_question,
  gtm_trigger,
  gtm_answer
}) => {
  const handleChange = (e) => {
    if (gtm_trigger) {
      GTMDataLayer({
        clickEvent: clickevent,
        question: gtm_question,
        answer: gtm_answer,
      });
    }
    onChange(e);
  };
  const OptionData = OptionValue.map((obj, index) => {
    return (
      <option value={obj.value} key={index}>
        {obj.label}
      </option>
    );
  });
  return (
    <>
      <select className={className} name={name} ref={myRef} onChange={handleChange} id={id} value={value}>
        {placeholderOption && <option value=''>{placeholderOption}</option>}
        {OptionData}
      </select>
      {validationMsg && (
        <>
          <span className="error_msg error">{validationMsg}</span>
        </>
      )}
    </>
  );
};

export default SelectBox;
