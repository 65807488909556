import React, { useReducer, useState, useContext } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import ProgressBar from "./ProgressBar";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Button from "../../../UI/Button";
import returnImg from "../../../../assets/img/S_GYR_V1/return.png";
import editImg from "../../../../assets/img/S_GYR_V1/edit.png";
import SelectBox from "../../../UI/SelectBox";
import PreviousPostCode from "./PreviousPostCode";
import { slideValidation } from "../../../../Utility/Validations";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PostCode = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  className,
  backClick,
  splitForm,
  getValues,
  reset,
  step,
  loadPercent,
  scrolltobtn,
  gtm_question
}) => {
  const { getPostcodeAddress, getSplitPostcodeAddress, isLoading } = usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [manualAddress, setManualAddress] = useState(false);
  const [prePostcodeStatus, setPrePostcodeStatus] = useState({});
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    setManualAddress(false);
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(e.target.value, visitorParameters.visitor_parameters.uuid).then(res => {
        if (res.data.status === "0") {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
        if (res.data.length > 0) {
          dispatch({
            type: "validationSucess",
            payload: {
              lookup: false,
              next_click: true,
              postcode: e.target.value,
              get_address: res.data,
              check_select: true,
              manual_address: "hide",
            },
          });
        }
      }).catch(
        function () {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
      );
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    setManualAddress(false);
    dispatch({
      type: "getExtraAddressDetails",
      payload: { get_details: [], manual_address: "hide" },
    });
    setPostCodeAddress([]);
    clearErrors([
      "txtHouseNumber",
      "txtAddress3",
      "txtCounty",
      "txtTown",
      "txtCountry",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode,
        visitorParameters.visitor_parameters.uuid
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtHouseNumber",
        "txtAddress3",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
      setTimeout(() => {
        scrolltobtn.current.scrollIntoView({
          behavior: "smooth",
          block: 'end'
        });
      }, 50);
    }
    else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtPostCode");
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtHouseNumber").value;
    const addressLine2 = document.getElementById("txtAddress3").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const addressValidation = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await trigger("txtPostCode");
    var manual_txtHouseNumber;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPostCode) {
      manual_txtHouseNumber = await trigger("txtHouseNumber");
      txtAddress = await trigger("txtAddress1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (manual_txtHouseNumber) {
      manual_txtTown = await trigger("txtTown");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountry");
    } else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLink === 0) {
      const address1 = await trigger("txtAddress1");
      if (!address1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      checkPostCodeEntry();
      slideChange(e);
    } else {
      return false;
    }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );
  const handleOnchange = (e) => {
    setTimeout(() => {
      scrolltobtn.current.scrollIntoView({
        behavior: "smooth",
        block: 'end'
      });
    }, 50);
    var prevAddressStatus = ((e.target.value == '6' && e.target.name == 'question_3') || (e.target.value == '7' && e.target.name == 'question_4'));
    ((!prevAddressStatus && e.target.name == 'question_3')) ? setPrePostcodeStatus({}) :
      setPrePostcodeStatus((prevState) => ({
        ...prevState,
        [e.target.name]: prevAddressStatus,
      }));

  }
  const reSearchPostcode = () => {
    dispatch({ type: "resetaddress", get_address: [], });
    setManualAddress(false);
    document.getElementById('txtPostCode').value = '';
  }
  const validatePostcodeSlide = async (e) => {
    const values = getValues();
    var section1 = ['txtPostCode', 'txtAddress1', 'txtHouseNumber', 'txtTown', 'txtCountry'];
    var section2 = (values?.question_3 == '6') ? ['txtPrevPostCode_1', 'txtPrevAddress1_1', 'txtPrevHouseNumber_1', 'txtPrevTown_1', 'txtPrevCountry_1'] : [];
    var section3 = (values?.question_4 == '7') ? ['txtPrevPostCode_2', 'txtPrevAddress1_2', 'txtPrevHouseNumber_2', 'txtPrevTown_2', 'txtPrevCountry_2'] : [];
    var validationArr = [...section1, ...section2, ...section3];
    const validationCheck = await slideValidation(validationArr, trigger);
    if (validationCheck == 'Success') {
      triggerSlideGtm();
      slideChange(e);
    }
  }
  const triggerSlideGtm = () => {
    let gtmData = ['address'];
    for (const item of gtmData) {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: item,
        answer: '',
      });
    }

  }
  return (
    <>
      <div className={`form-view animate__animated animate__fadeInUp animate__delay-.5s ${className}`}>
        <ProgressBar step={step} load={loadPercent} />
        <HiddenField
          name="pCode_EntryType"
          inputId="pCode_EntryType"
          inputValue={postCodeEntry}
        />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s mb-0">
          <label className="mb-2">Current Address <em>*</em></label>
          <div id="lookup_field" className="row">
            <div className="col-lg-8 col-md-8 col-12 pb-3">
              <AddressTextField
                type="text"
                placeholder="Enter Your Postcode"
                name="txtPostCode"
                id="txtPostCode"
                className="wpforms-field-address-address1"
                dataId="txtPostCode"
                onBlur={checkValidation}
                autoComplete="off"
                onChange={() => clearErrors("txtPostCode")}
                validation={validation({
                  required: "Please Enter Postcode",
                  pattern: {
                    value: /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                    message: "Please Enter a Valid Postcode",
                  },
                  minLength: {
                    value: 5,
                    message: "Please Enter a Valid Postcode",
                  },
                })}
                validationMsg={
                  validationMsg.txtPostCode && validationMsg.txtPostCode.message
                }
              />
            </div>
            <div className="col-lg-4 col-md-4 col-12 pb-3">
              <Button
                type="button"
                name={`find_address`}
                id={`idpc_button1`}
                className="msf-nav-button next button color-1"
                buttonText={isLoading ? 'Searching...' : 'Find Address'}
                onClick={lookupValidation}
              />
            </div>
            {state.checkSelect && <div className="col-lg-8 col-md-8 col-12 pb-3 idpc_dropdown1" id="idpc_address1" >
              <AddressSelectBox
                className="valid"
                OptionValue={state.getAddress}
                name="txtAddress1"
                id="txtAddress1"
                onChange={(e) => { getValue(e); clearErrors('txtAddress1') }}
                myRef={validation({ required: "Please select address" })}
                validationMsg={
                  validationMsg.txtAddress1 && validationMsg.txtAddress1.message
                }
              />
            </div>}
          </div>
          {!manualAddress && <><div className={`Mod_detail py-3 px-3 animate__animated animate__fadeInUp animate__delay-.5s ${state.showManualAddress}`}>
            <p className="ADD_clr_typ">
              {state.getDetails.line_1 && (<>{state.getDetails.line_1}<br /></>)}
              {state.getDetails.line_2 && (<>{state.getDetails.line_2}<br /></>)}
              {state.getDetails.line_3 && (<>{state.getDetails.line_3}<br /></>)}
              {state.getDetails.county && (<>{state.getDetails.county}<br /></>)}
              {state.getDetails.town && (<>{state.getDetails.town}<br /></>)}
              {state.getDetails.country && (<>{state.getDetails.country}<br /></>)}
            </p>
            <p className="ADD_na_top mb-0">
              {state.getDetails.postcode}
            </p>
          </div>

            <div className={`return_back ${state.showManualAddress}`}>
              <button type="button" className="mb-0 link-btn pcode_btn" onClick={() => { reSearchPostcode() }}><img src={returnImg} alt="" />Search Again</button>
              <button type="button" className="mb-0 link-btn pcode_btn float-right" onClick={(e) => { setManualAddress(true) }}><img src={editImg} alt="" />Edit</button>
            </div></>}

        </div>
        <div className={`slide8_add row ${manualAddress ? '' : 'd-none'}`}>
          <div className={`col-lg-12 form-group required-field adj_width mb-1`}>
            <AddressTextField
              type="text"
              placeholder="Address Line 1 *"
              name="txtHouseNumber"
              id="txtHouseNumber"
              className="form-control  hero-input"
              dataId="txtHouseNumber"
              autoComplete="off"
              readonly={false}
              validation={validation({
                required: "Please Enter Address Line 1",
              })}
              validationMsg={
                validationMsg.txtHouseNumber &&
                validationMsg.txtHouseNumber.message
              }
              value={state.getDetails.line_1}
              onChange={() => { clearErrors('txtHouseNumber') }}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div>

          <div className={`col-lg-12 form-group adj_width mb-1`}>
            <AddressTextField
              type="text"
              placeholder="Address Line 2"
              name="txtAddress3"
              id="txtAddress3"
              className="form-control  hero-input"
              dataId="txtAddress3"
              autoComplete="off"
              readonly={false}
              validation={validation}
              // validation={validation({
              //   required: "Please Enter Address Line 2",
              // })}
              // value={state.getDetails.line_2}
              // validationMsg={
              //   validationMsg.txtAddress3 &&
              //   validationMsg.txtAddress3.message
              // }
              onChange={() => { clearErrors('txtAddress3') }}
              value={state.getDetails.line_2}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div>

          {/* <div className={`col-lg-12 form-group adj_width mb-1`}>
            <AddressTextField
              type="text"
              placeholder="Street"
              name="txtStreet"
              id="txtStreet"
              className="form-control  hero-input"
              dataId="txtStreet"
              autoComplete="off"
              readonly={false}
              // validation={validation({
              //   required: "Please Enter Street",
              // })}
              // validationMsg={
              //   validationMsg.txtStreet &&
              //   validationMsg.txtStreet.message
              // }
              value={state.getDetails.line_3}
              onChange={() => { clearErrors('txtStreet') }}
              validation={validation}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div> */}

          <div className={`col-lg-12 form-group adj_width mb-1`}>
            <AddressTextField
              type="text"
              placeholder="County"
              name="txtCounty"
              id="txtCounty"
              className="form-control  hero-input"
              dataId="txtCounty"
              autoComplete="off"
              readonly={false}
              // validation={validation({
              //   required: "Please Enter County",
              // })}
              // validationMsg={
              //   validationMsg.txtCounty &&
              //   validationMsg.txtCounty.message
              // }
              value={state.getDetails.county}
              onChange={() => { clearErrors('county') }}
              validation={validation}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div>

          <div className={`col-lg-12 form-group adj_width mb-1`}>
            <AddressTextField
              type="text"
              placeholder="Town *"
              name="txtTown"
              id="txtTown"
              className="form-control  hero-input"
              dataId="txtTown"
              autoComplete="off"
              readonly={false}
              validation={validation({ required: "Please Enter Town" })}
              validationMsg={
                validationMsg.txtTown && validationMsg.txtTown.message
              }
              value={state.getDetails.town}
              onChange={() => { clearErrors('txtTown') }}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div>
          <div className={`col-lg-12 form-group adj_width mb-1`}>
            <AddressTextField
              type="text"
              placeholder="Country *"
              name="txtCountry"
              id="txtCountry"
              className="form-control  hero-input"
              dataId="txtCountry"
              autoComplete="off"
              readonly={false}
              validation={validation({ required: "Please Enter Country" })}
              validationMsg={
                validationMsg.txtCountry && validationMsg.txtCountry.message
              }
              value={state.getDetails.country}
              onChange={() => { clearErrors('txtCountry') }}
            />
            <i
              className="validate validate_success"
              aria-hidden="true"
              style={{ display: "none" }}
            ></i>
          </div>
          {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
        </div>
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
          <label className="mb-2">{gtm_question.question} <em>*</em>
          </label>
          <SelectBox
            className="required"
            OptionValue={gtm_question.answers}
            name={`question_${gtm_question.questionId}`}
            id={`question_${gtm_question.questionId}`}
            onChange={handleOnchange}
            clearErrors={clearErrors}
            myRef={validation({ required: "Please Select" })}
            validationMsg={
              validationMsg[`question_${gtm_question.questionId}`] &&
              validationMsg[`question_${gtm_question.questionId}`].message
            }
            clickevent={gtm_question.clickevent}
            gtm_question={gtm_question.gtmQuestion}
            gtm_answer=''
            gtm_trigger={gtm_question.gtm_trigger}
          />

        </div>
        {prePostcodeStatus[`question_${gtm_question.questionId}`] && <>
          <PreviousPostCode
            title="Previous Address"
            validation={validation}
            validationMsg={validationMsg}
            clearErrors={clearErrors}
            setError={setError}
            slideChange={slideChange}
            trigger={trigger}
            className={``}
            backClick={backClick}
            splitForm={splitForm}
            getValues={getValues}
            preCount='1'
            scrolltobtn={scrolltobtn}
          />
          <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
            <label className="mb-2">Have you lived at any other addresses in the last 10 years? <em>*</em>
            </label>
            <SelectBox
              className="required"
              OptionValue={[
                { label: "No", value: "8" },
                { label: "Yes", value: "7" }]}
              name="question_4"
              id="question_4"
              onChange={(e) => { handleOnchange(e); }}
              clearErrors={clearErrors}
              myRef={validation({ required: "Please Select" })}
              validationMsg={
                validationMsg.question_4 &&
                validationMsg.question_4.message
              }
            ></SelectBox>
          </div></>}
        {prePostcodeStatus.question_4 &&
          <PreviousPostCode
            title="Other Address"
            validation={validation}
            validationMsg={validationMsg}
            clearErrors={clearErrors}
            setError={setError}
            slideChange={slideChange}
            trigger={trigger}
            className={``}
            backClick={backClick}
            splitForm={splitForm}
            getValues={getValues}
            preCount='2'
            scrolltobtn={scrolltobtn}
          />}

        <div className="form-sub_btn" ref={scrolltobtn}>
          <Button
            type="button"
            name={`postcode`}
            id={`back_postcode`}
            className="msf-nav-button next button color-1"
            buttonText="Previous"
            onClick={backClick}
          />
          <Button
            type="button"
            name={`postcode`}
            id={`next_postcode`}
            className="msf-nav-button next button color-1"
            buttonText="Next"
            onClick={validatePostcodeSlide}
          />
        </div>

      </div>
    </>
  );
};
export default PostCode;
