export const Lender= [
  { id: 35, label: "Aldermore Group", value: 35, gtmAnswer: 'Aldermore Group' },
  { id: 1, label: "Alphera Financial Services", value: 1, gtmAnswer: 'Alphera Financial Services' },
  { id: 2, label: "Audi Finance", value: 2, gtmAnswer: 'Audi Finance' },
  { id: 3, label: "Barclays Partner Finance", value: 3, gtmAnswer: 'Barclays Partner Finance' },
  { id: 4, label: "Bentley Financial Services", value: 4, gtmAnswer: 'Bentley Financial Services' },
  { id: 5, label: "Black Horse", value: 5, gtmAnswer: 'Black Horse' },
  { id: 6, label: "Blue Motor Finance", value: 6, gtmAnswer: 'Blue Motor Finance' },
  { id: 7, label: "BMW Financial Services", value: 7, gtmAnswer: 'BMW Financial Services' },
  { id: 8, label: "Close Brothers Motor Finance", value: 8, gtmAnswer: 'Close Brothers Motor Finance' },
  { id: 9, label: "Clydesdale Finance", value: 9, gtmAnswer: 'Clydesdale Finance' },
  { id: 36, label: "Creation Financial Services Limited", value: 36, gtmAnswer: 'Creation Financial Services Limited' },
  { id: 10, label: "Ford Credit", value: 10, gtmAnswer: 'Ford Credit' },
  { id: 11, label: "Hitachi Capital", value: 11, gtmAnswer: 'Hitachi Capital' },
  { id: 12, label: "Honda Finance", value: 12, gtmAnswer: 'Honda Finance' },
  { id: 37, label: "HCP Finance", value: 37, gtmAnswer: 'HCP Finance' },
  { id: 38, label: "Hyundai Finance", value: 38, gtmAnswer: 'Hyundai Finance' },
  { id: 13, label: "Jaguar", value: 13, gtmAnswer: 'Jaguar' },
  { id: 14, label: "JBR Capital", value: 14, gtmAnswer: 'JBR Capital' },
  { id: 39, label: "Kia", value: 39, gtmAnswer: 'Kia' },
  { id: 15, label: "Lamborghini Finance", value: 15, gtmAnswer: 'Lamborghini Finance' },
  { id: 16, label: "Land Rover", value: 16, gtmAnswer: 'Land Rover' },
  { id: 17, label: "Mann Island Finance", value: 17, gtmAnswer: 'Mann Island Finance' },
  { id: 40, label: "Marsh Finance", value: 40, gtmAnswer: 'Marsh Finance' },
  { id: 18, label: "Mercedes-Benz Financial Services", value: 18, gtmAnswer: 'Mercedes-Benz Financial Services' },
  { id: 19, label: "Mitsubishi", value: 19, gtmAnswer: 'Mitsubishi' },
  { id: 20, label: "Moneyway", value: 20, gtmAnswer: 'Moneyway' },
  { id: 21, label: "MotoNovo", value: 21, gtmAnswer: 'MotoNovo' },
  { id: 41, label: "Nissan Finance", value: 41, gtmAnswer: 'Nissan Finance' },
  { id: 22, label: "Northridge Finance", value: 22, gtmAnswer: 'Northridge Finance' },
  { id: 23, label: "Paragon Motor Finance", value: 23, gtmAnswer: 'Paragon Motor Finance' },
  { id: 24, label: "Porsche Finance", value: 24, gtmAnswer: 'Porsche Finance' },
  { id: 42, label: "RCI Financial Services", value: 42, gtmAnswer: 'RCI Financial Services' },
  { id: 25, label: "Santander Consumer Finance", value: 25, gtmAnswer: 'Santander Consumer Finance' },
  { id: 26, label: "Seat Finance", value: 26, gtmAnswer: 'Seat Finance' },
  { id: 27, label: "Skoda", value: 27, gtmAnswer: 'Skoda' },
  { id: 28, label: "Startline", value: 28, gtmAnswer: 'Startline' },
  { id: 29, label: "Suzuki Financial Services Ltd (Black Horse)", value: 29, gtmAnswer: 'Suzuki Financial Services Ltd (Black Horse)' },
  { id: 30, label: "Toyota", value: 30, gtmAnswer: 'Toyota' },
  { id: 31, label: "Vauxhall Finance", value: 31, gtmAnswer: 'Vauxhall Finance' },
  { id: 32, label: "Volkswagen Financial Services (VWFS)", value: 32, gtmAnswer: 'Volkswagen Financial Services (VWFS)' },
  { id: 33, label: "Volvo", value: 33, gtmAnswer: 'Volvo' },
  { id: 34, label: "Zopa", value: 34, gtmAnswer: 'Zopa' }
];

