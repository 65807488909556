import React from "react";
import SRA from "../../../../assets/img/S_GYR_V1/SRA.png";
import sws_privacy_policy from "../../../../assets/pdf/sws_privacy_policy.pdf";
import sws_terms from "../../../../assets/pdf/sws_terms.pdf";
import sws_terms_conditions from "../../../../assets/pdf/sws_terms_conditions.pdf"
const Footer = () => {
  return (
    <>
      <footer className="py-4 text-center">
        <div className="container">
          <div className="col-lg-12">
            <div className="row mb-3">
              <div className="col-lg-8 col-12 py-2">
                <p>Get Your Refund is a trading style of Stephens Wilmot Ltd, registered in England and Wales (Company number <a href="https://find-and-update.company-information.service.gov.uk/company/10411634" target="_blank" rel="noopener external" data-wpel-link="external" className="wpel-icon-right">10411634<i className="wpel-icon fa fa-external-link" aria-hidden="true"></i></a>). Authorised and regulated by the Solicitors Regulation Authority (SRA number 635237). <span className="OYPEnA text-decoration-none text-strikethrough-none">VAT registration number 305904126. </span>Our address is Mamhilad House, Mamhilad Park Estate, Pontypool, NP4 0HZ.&nbsp;
                {/* <span style={{ color: "var( --e-global-color-5912140 )", backgroundColor: "var( --e-global-color-e3199eb )" }}>© Stephens Wilmot Limited 2024</span> */}
                </p>
                <p>
                If your claim is successful, our fee is 25% plus VAT of any compensation amount (30% total). You do not need to use a legal representative; you can complain directly to your lender and refer your complaint to the Financial Ombudsman Service for free.
                </p>
                <p>
                No win, no fee: If your claim is successful, our fee is 25% plus VAT of any compensation amount. If you cancel your claim after the 14-day cooling-off period, you will be liable to pay us for the reasonable costs we have incurred in relation to our Services.
                See our <a target="blank" href={sws_terms_conditions} style={{color:'blue'}}>Terms and Conditions</a>  for more information.
                </p>
                <div>
                  <p className="m-0"><a target="blank" href={sws_privacy_policy}>Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a target="blank" href={sws_terms}>Terms of Use</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-12 py-2">
                {/* <!-- Start of SRA Digital Badge code --> */}
                <div style={{ maxWidth: 275, maxHeight: 163 }}>
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "59.1%",
                      height: "auto",
                      overflow: "hidden"
                    }}
                  >
                    <iframe
                      frameBorder={0}
                      scrolling="no"
                      allowtransparency="true"
                      src="https://cdn.yoshki.com/iframe/55845r.html"
                      style={{
                        border: 0,
                        margin: 0,
                        padding: 0,
                        backgroundcolor: "transparent",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        position: "absolute"
                      }}
                    />
                  </div>
                </div>

                {/* <!-- End of SRA Digital Badge code --> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;