import React, { useContext, useState } from "react";
import ProgressBar from "./ProgressBar";
import SelectBox from "../../../UI/SelectBox";
import { Salutation } from "../../../../Constants/Constants";
import TextField from "../../../UI/TextField";
import Button from "../../../UI/Button";
import { DateOfBirth } from "../../../../Utility/DateOfBirth";
import Email from "../../../UI/Email";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import Telephone from "../../../UI/Telephone";
import shield from "../../../../assets/img/S_GYR_V1/shield.png";
import { slideValidation } from "../../../../Utility/Validations";
import { numberValidation, nameValidate } from "../../../../Utility/Validations";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const PersonalDetails = (props) => {
  const [nextClick, setNextClick] = useState({ 'sec2': false, 'sec3': false, 'sec4': false });
  const [prevName, setPrevName] = useState('d-none');
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [contactValidation, setContactValidation] = useState({ 'txtEmail': false, 'txtPhone': false });
  const handleNextClick = async (e) => {
    var values = props.getValues();
    var section1 = ['txtFName', 'txtMName', 'txtLName'];
    var section2 = (values?.question_2) == '3' ? ['question_2', 'txtPreFName', 'txtPreMName', 'txtPreLName'] : ['question_2'];
    var section3 = ['DobDay', 'DobMonth', 'DobYear'];
    var section4 = ['txtEmail', 'txtPhone'];

    var nextSection = Object.entries(nextClick).find(([key, value]) => value === false);
    var validationArr = nextSection?.[0] === 'sec2' ? section1 :
      nextSection?.[0] === 'sec3' ? [...section1, ...section2] :
        nextSection?.[0] === 'sec4' ? [...section1, ...section2, ...section3] :
          [...section1, ...section2, ...section3, ...section4];

          const validationCheck = await slideValidation(validationArr, props.trigger);
    const contactStatus = Object.entries(contactValidation).find(([key, value]) => value === false);
    if (validationCheck == 'Success') {
      if (nextSection) {
        setNextClick((prevState) => ({
          ...prevState,
          [nextSection[0]]: true,
        }));
        setTimeout(() => {
          props.scrolltobtn.current.scrollIntoView({
            behavior: "smooth",
            block: 'end'
          });
        }, 50);
        triggerSlideGtm(nextSection[0]);
      }
      else {
        if (contactStatus) {
          (contactStatus[0] == 'txtEmail') ? emailValidCheck() : phoneValidCheck();
          return false;
        }
        triggerSlideGtm('sec5');
        props.slideChange(e);
      }
    }
  };
  const handleOnchange = (e) => {
    var clickVal = ['3', '4']; 
    if (clickVal.includes(e.target.value)) {
      setTimeout(() => {
        props.scrolltobtn.current.scrollIntoView({
          behavior: "smooth",
          block: 'end'
        });
      }, 50);
    }
    if (e.target.value == '3') {
      setPrevName('');
    } else {
      // document.getElementById('lstPreSalutation').value = '';
      document.getElementById('txtPreFName').value = '';
      document.getElementById('txtPreMName').value = '';
      document.getElementById('txtPreLName').value = '';
      setPrevName('d-none');
    }
  }
  const emailValidCheck = async () => {
    const values = props.getValues();
    const email = values['txtEmail'];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email, visitorParameters.visitor_parameters.uuid)
      if (getEmailValidation.data.status_code === 1) {
        setContactValidation(prevState => ({
          ...prevState,
          txtEmail: true,
        }));
        props.clearErrors("txtEmail");
        return 1;
      } else {
        setContactValidation(prevState => ({
          ...prevState,
          txtEmail: false,
        }));
        props.setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    }
  };
  const phoneValidCheck = async () => {
    const values = props.getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone, visitorParameters.visitor_parameters.uuid)
      if (getPhoneValidation.data.status_code === 0) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: true,
        }));
        props.clearErrors("txtPhone");
        return 1;
      }
    }
  };
  const eventOnchange = (e) => {
    var field = e.target.id;
    setContactValidation(prevState => ({
      ...prevState,
      [field]: false,
    }));
    if (e.target.name = 'txtphone' && e.target.value.length >= 11) phoneValidCheck()
  }
  const triggerSlideGtm = (section) =>{
    const sections = {
      sec2: ['name'],
      sec3: ['different name'],
      sec4: ['dob'],
      sec5: ['contact details']
    };
  
    const gtmData = sections[section] || [];
    for (const item of gtmData) {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: item,
        answer: '',
      });
  }
    
  }
  return (
    <>
      <div className={`form-view animate__animated animate__fadeInUp animate__delay-.5s ${props.className}`}>
        <ProgressBar step={props.step} load={props.loadPercent} />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
          <label>Name <em>*</em><span>Enter your name exactly as it appears on your driving licence.</span>
          </label>
          <div className="row">
            {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label className="m-0 top_line">Title</label>
              <SelectBox
                className="required"
                OptionValue={Salutation}
                name="lstSalutation"
                id="lstSalutation"
                onChange={() => props.clearErrors("lstSalutation")}
                clearErrors={props.clearErrors}
                myRef={props.validation({ required: "Please Select Title" })}
                validationMsg={
                  props.validationMsg.lstSalutation &&
                  props.validationMsg.lstSalutation.message
                }
              ></SelectBox>
            </div> */}
            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label className="m-0 top_line">First name</label>
              <TextField
                type="text"
                className="required"
                placeholder="First Name"
                name="txtFName"
                id="txtFName"
                onkeypress={(e) => nameValidate(e)}
                onChange={(e) => { props.clearErrors("txtFName"); e.target.value = e.target.value.trimStart() }}
                onBlur={(e) => { e.target.value = e.target.value.trim() }}
                validation={props.validation({
                  required: "Please Enter First Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid First Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}

              ></TextField>
              {props.validationMsg.txtFName && (
                <span
                  className="error_msg error"
                  id="txtFName_err"
                >
                  {props.validationMsg.txtFName.message}
                </span>
              )}
            </div>
            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label className="m-0 top_line">Middle name</label>
              <TextField
                type="text"
                className="required"
                placeholder="Middle Name"
                name="txtMName"
                id="txtMName"
                onkeypress={(e) => nameValidate(e)}
                onChange={(e) => { props.clearErrors("txtMName"); e.target.value = e.target.value.trimStart() }}
                onBlur={(e) => { e.target.value = e.target.value.trim() }}
                validation={props.validation({
                  // required: "Please Enter Middle Name",
                  // minLength: {
                  //   value: 3,
                  //   message: "Please Enter Valid Middle Name",
                  // },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}

              ></TextField>
              {props.validationMsg.txtMName && (
                <span
                  className="error_msg error"
                  id="txtMName_err"
                >
                  {props.validationMsg.txtMName.message}
                </span>
              )}
            </div>
            <div className="col-lg-4 col-md-4 col-12 mb-3">
              <label className="m-0 top_line">Last name</label>
              <TextField
                type="text"
                className="required"
                placeholder="Last Name"
                name="txtLName"
                id="txtLName"
                onkeypress={(e) => nameValidate(e)}
                onChange={(e) => { props.clearErrors("txtLName"); e.target.value = e.target.value.trimStart() }}
                onBlur={(e) => { e.target.value = e.target.value.trim() }}
                validation={props.validation({
                  required: "Please Enter Last Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid Last Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}

              ></TextField>
              {props.validationMsg.txtLName && (
                <span
                  className="error_msg error"
                  id="txtLName_err"
                >
                  {props.validationMsg.txtLName.message}
                </span>
              )}
            </div>
          </div>
        </div>

        {nextClick.sec2 && <><div className="field animate__animated animate__fadeInUp animate__delay-.5s pt-2 next_field1">
          <label>{props.gtm_question.question} <em>*</em>
            <span>For example, if you have changed your name through marriage or deed poll.</span>
          </label>
          <SelectBox
            className="required"
            OptionValue={props.gtm_question.answers}
            placeholderOption="Please select"
            name={`question_${props.gtm_question.questionId}`}
            id={`question_${props.gtm_question.questionId}`}
            onChange={(e) => { props.clearErrors([`question_${props.gtm_question.questionId}`]); handleOnchange(e); }}
            clearErrors={props.clearErrors}
            myRef={props.validation({ required: "Please Select" })}
            validationMsg={
              props.validationMsg[`question_${props.gtm_question.questionId}`] &&
              props.validationMsg[`question_${props.gtm_question.questionId}`].message
            }
            clickevent={props.gtm_question.clickevent}
            gtm_question={props.gtm_question.gtmQuestion}
            gtm_answer=''
            gtm_trigger={props.gtm_question.gtm_trigger}
          ></SelectBox>
        </div>

          <div className={`field animate__animated animate__fadeInUp animate__delay-.5s pt-2 pre_det ${prevName}`}>
            <label className="mb-2">Previous Name <em>*</em>
            </label>
            <div className="row">
              {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                <label className="m-0 top_line">Title</label>
                <SelectBox
                  className="required"
                  OptionValue={Salutation}
                  name="lstPreSalutation"
                  id="lstPreSalutation"
                  onChange={() => props.clearErrors("lstPreSalutation")}
                  clearErrors={props.clearErrors}
                  myRef={props.validation({ required: "Please Select Title" })}
                  validationMsg={
                    props.validationMsg.lstPreSalutation &&
                    props.validationMsg.lstPreSalutation.message
                  }
                ></SelectBox>
              </div> */}
              <div className="col-lg-4 col-md-4 col-12 mb-3">
                <label className="m-0 top_line">First name</label>
                <TextField
                  type="text"
                  className="required"
                  placeholder="First Name"
                  name="txtPreFName"
                  id="txtPreFName"
                  onkeypress={(e) => nameValidate(e)}
                  onChange={(e) => { props.clearErrors("txtPreFName"); e.target.value = e.target.value.trimStart() }}
                  onBlur={(e) => { e.target.value = e.target.value.trim() }}
                  validation={props.validation({
                    required: "Please Enter First Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid First Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}

                ></TextField>
                {props.validationMsg.txtPreFName && (
                  <span
                    className="error_msg error"
                    id="txtPreFName_err"
                  >
                    {props.validationMsg.txtPreFName.message}
                  </span>
                )}
              </div>
              <div className="col-lg-4 col-md-4 col-12 mb-3">
                <label className="m-0 top_line">Middle name</label>
                <TextField
                  type="text"
                  className="required"
                  placeholder="Middle Name"
                  name="txtPreMName"
                  id="txtPreMName"
                  onkeypress={(e) => nameValidate(e)}
                  onChange={(e) => { props.clearErrors("txtPreMName"); e.target.value = e.target.value.trimStart() }}
                  onBlur={(e) => { e.target.value = e.target.value.trim() }}
                  validation={props.validation({
                    // required: "Please Enter Middle Name",
                    // minLength: {
                    //   value: 3,
                    //   message: "Please Enter Valid Middle Name",
                    // },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}

                ></TextField>
                {props.validationMsg.txtPreMName && (
                  <span
                    className="error_msg error"
                    id="txtPreMName_err"
                  >
                    {props.validationMsg.txtPreMName.message}
                  </span>
                )}
              </div>
              <div className="col-lg-4 col-md-4 col-12 mb-3">
                <label className="m-0 top_line">Last name</label>
                <TextField
                  type="text"
                  className="required"
                  placeholder="Last Name"
                  name="txtPreLName"
                  id="txtPreLName"
                  onkeypress={(e) => nameValidate(e)}
                  onChange={(e) => { props.clearErrors("txtPreLName"); e.target.value = e.target.value.trimStart() }}
                  onBlur={(e) => { e.target.value = e.target.value.trim() }}
                  validation={props.validation({
                    required: "Please Enter Last Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid Last Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}

                ></TextField>
                {props.validationMsg.txtPreLName && (
                  <span
                    className="error_msg error"
                    id="txtPreLName_err"
                  >
                    {props.validationMsg.txtPreLName.message}
                  </span>
                )}
              </div>
            </div>
          </div></>}

        {nextClick.sec3 && <><div className="field animate__animated animate__fadeInUp animate__delay-.5s pt-2 next_field2" >
          <label className="mb-2">Date of Birth <em>*</em>
          </label>
          <DateOfBirth
            clearErrors={props.clearErrors}
            validation={props.validation}
            validationMsg={props.validationMsg}
            getValues={props.getValues}
            setError={props.setError}
            gtm_trigger="true"
          />

        </div></>}


        {nextClick.sec4 && <><div className="field animate__animated animate__fadeInUp animate__delay-.5s secure pt-2 next_field3">
          <label className="mb-2">Email Address <em>*</em>
            <span>If you don't complete an enquiry you've started, we may remind you by email and text.
            </span>
          </label>

          <Email
            name="txtEmail"
            className="requied secure-img"
            id="txtEmail"
            placeholder="Email Addresss"
            onBlur={emailValidCheck}
            onKeyPress={e => { if (e.key === ' ') e.preventDefault() }}
            onChange={(e) => { props.clearErrors("txtEmail"); eventOnchange(e) }}
            validation={props.validation({
              required: "Please Enter Valid Email Address",
            })}
          ></Email>
          <span className="secure"><span><img src={shield} alt="" />Secure</span></span>
          {props.validationMsg.txtEmail && (
            <span
              className="error_msg error"
              id="txtEmail_err"
            >
              {props.validationMsg.txtEmail.message}
            </span>
          )}
        </div>

          <div className="field animate__animated animate__fadeInUp animate__delay-.5s secure pt-2 next_field3">
            <label className="mb-2">Mobile Number <em>*</em></label>
            <span className="secure"><span><img src={shield} alt="" />Secure</span></span>
            <Telephone
              name="txtPhone"
              id="txtPhone"
              className="required telephone_number secure-img"
              placeholder="Phone Number"
              onBlur={phoneValidCheck}
              onChange={(e) => { props.clearErrors("txtPhone"); eventOnchange(e); }}
              onKeyPress={e => { numberValidation(e) }}
              maxlength="11"
              minLength="11"
              validation={props.validation({
                pattern: {
                  value: /^[0-9]*$/i,
                  message: "Please Enter Valid Phone Number",
                },
                required: "Please Enter Phone Number",
              })}
            ></Telephone>
            {props.validationMsg.txtPhone && (
              <span
                className="error_msg error"
                id="phone_err"
              >
                {props.validationMsg.txtPhone && "Please Enter Valid Phone Number"}
              </span>
            )}
          </div></>}


        <div className="form-sub_btn next_fieldBtn4" ref={props.scrolltobtn} >
          <Button
            type="button"
            name={`personal_details`}
            id={`back_personal`}
            className="msf-nav-button next button color-1"
            buttonText="Previous"
            onClick={props.backClick}
          />
          <Button
            type="button"
            name={`personal_details`}
            id={`next_personal`}
            onClick={handleNextClick}
            className="msf-nav-button next button color-1"
            buttonText="Next"
          />
        </div>
      </div>
    </>
  )
}

export default PersonalDetails;