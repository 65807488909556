const questinnare1 = {
    questionId: 5,
    question: "Q1 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    answers: [
        {
            label: "Yes",
            value: "14"
        },
        {
            label: "No",
            value: "15"
        }
    ]
};
const questinnare2 = {
    questionId: 6,
    question: "Q2 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    answers: [
        {
            label: "Yes",
            value: "16"
        },
        {
            label: "No",
            value: "17"
        }
    ]
};
const questinnare3 = {
    questionId: 7,
    question: "Q3 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    answers: [
        {
            label: "Yes",
            value: "18"
        },
        {
            label: "No",
            value: "19"
        }
    ]
};
const questinnare4 = {
    questionId: 8,
    question: "Q4 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    answers: [
        {
            label: "Yes",
            value: "20"
        },
        {
            label: "No",
            value: "21"
        }
    ]
};
const questinnare5 = {
    questionId: 9,
    question: "Q5 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    answers: [
        {
            label: "Yes",
            value: "22"
        },
        {
            label: "No",
            value: "23"
        }
    ]
};
const questinnare6 = {
    questionId: 10,
    question: "Q6 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    answers: [
        {
            label: "Yes",
            value: "24"
        },
        {
            label: "No",
            value: "25"
        }
    ]
};

const questinnare7Sub1 = {
    question: "Q7 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    questionId: 'sub1',
    answers: [
        {
            label: "Option-1",
            value: "Option-1"
        },
        {
            label: "Option-2",
            value: "Option-2"
        },
        {
            label: "Option-3",
            value: "Option-3"
        },
        {
            label: "Option-4",
            value: "Option-4"
        },
        {
            label: "Option-5",
            value: "Option-5"
        }
    ]
}
const questinnare7Sub2 = {
    question: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    questionId: 'sub2',
    answers: [
        {
            label: "Yes",
            value: "yes"
        },
        {
            label: "No",
            value: "no"
        }
    ]
}
const questinnare7 = {
    questionId: 11,
    question: "Q7 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    answers: [
        {
            label: "Option-1",
            value: "26"
        },
        {
            label: "Option-2",
            value: "27"
        },
        {
            label: "Option-3",
            value: "28"
        },
        {
            label: "Option-4",
            value: "29"
        },
        {
            label: "Option-5",
            value: "30"
        },
        {
            label: "Option-6",
            value: "31"
        },
        {
            label: "Option-7",
            value: "32"
        },
        {
            label: "Option-8",
            value: "33"
        },
        {
            label: "Option-9",
            value: "34"
        },
        {
            label: "Option-10",
            value: "35"
        },
        {
            label: "Option-11",
            value: "36"
        },
        {
            label: "Option-12",
            value: "37"
        },
        {
            label: "Option-13",
            value: "38"
        },
        {
            label: "Option-14",
            value: "39"
        },
    ]
};
const questinnare8 = {
    questionId: 12,
    question: "Q8 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    answers: [
        {
            label: "Yes",
            value: "40"
        },
        {
            label: "No",
            value: "41"
        }
    ]
};

const Salutation = [
    {
        label: "Select Title",
        value: ""
    },
    {
        label: "Mr.",
        value: "Mr"
    },
    {
        label: "Mrs.",
        value: "Mrs"
    },
    {
        label: "Ms.",
        value: "Ms"
    },
    {
        label: "Miss.",
        value: "Miss"
    },
    {
        label: "Dr.",
        value: "Dr"
    },
    {
        label: "Rev.",
        value: "Rev"
    },
    {
        label: "Dame.",
        value: "Dame"
    },
    {
        label: "Lady.",
        value: "Lady"
    },
    {
        label: "Sir.",
        value: "Sir"
    },
    {
        label: "Lord.",
        value: "Lord"
    },
];
const questinnare9 = {
    questionId: 14,
    question: "Were you sold any other products in the past where you don't think this bank treated you fairly, which you would like to be reviewed?",
    answers: [
        {
            label: "Yes",
            value: "44"
        },
        {
            label: "No",
            value: "45"
        }
    ]
};

const questinnare10 = {
    questionId: 15,
    question: "Please name the products you would like to be reviewed?"
};


const questinnare11 = {
    questionId: 16,
    question: "Did you expect the advice you were given to be the best for your circumstances?",
    answers: [
        {
            label: "Yes",
            value: "46"
        },
        {
            label: "No",
            value: "47"
        }
    ]
};
const questinnare12 = {
    questionId: 17,
    question: "Does the bank contacting you over the phone cause you anxiety and stress?",
    answers: [
        {
            label: "Yes",
            value: "48"
        },
        {
            label: "No",
            value: "49"
        }
    ]
};

const questionnaire = {
    question_1: {
        questionId: 1,
        question: "Q1 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        gtmQuestion: "gtm question_1",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Yes",
                value: "1",
                gtmAnswer: 'yes',
            },
            {
                label: "No",
                value: "2",
                gtmAnswer: 'no'
            }
        ],

    },
    bank: {
        questionId: "2",
        question: "Select lender",
        gtmQuestion: "Select your lender",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            { id: 1, label: "Lender-1", value: 1, gtmAnswer: 'Lender-1' },
            { id: 2, label: "Lender-2", value: 2, gtmAnswer: 'Lender-2' },
            { id: 3, label: "Lender-3", value: 3, gtmAnswer: 'Lender-3' },
            { id: 4, label: "Lender-4", value: 4, gtmAnswer: 'Lender-4' },
            { id: 5, label: "Lender-5", value: 5, gtmAnswer: 'Lender-5' },
            { id: 6, label: "Lender-6", value: 6, gtmAnswer: 'Lender-6' },
            { id: 7, label: "Lender-7", value: 7, gtmAnswer: 'Lender-7' }
        ],
    },
    question_3: {
        questionId: '3',
        gtmQuestion: "gtm question_3",
        gtm_trigger:true,
        clickevent:'clickslide',
        question: "Q3 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        answers: [
            {
                label: "No",
                value: "11",
                gtmAnswer: 'no',
            },
            {
                label: "Yes",
                value: "10",
                gtmAnswer: 'yes',
            },
        ],
    },
    question_4: {
        questionId: '4',
        gtmQuestion: "gtm question_4",
        question: "Q4 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "No",
                value: "13",
                gtmAnswer: 'no',
            },
            {
                label: "Yes",
                value: "12",
                gtmAnswer: 'yes',
            },
          
        ],
    },
    question_5: {
        questionId: "5",
        question: "Q5 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        gtmQuestion: "gtm question_5",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Yes",
                value: "14",
                gtmAnswer: 'yes'
            },
            {
                label: "No",
                value: "15",
                gtmAnswer: "no"
            }
        ]
    },
    question_6: {
        questionId: "6",
        question: "Q6 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        gtmQuestion: "gtm question_6",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Yes",
                value: "16",
                gtmAnswer: "yes"
            },
            {
                label: "No",
                value: "17",
                gtmAnswer: "no"
            }
        ]
    },
    question_7: {
        questionId: "7",
        question: "Q7 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        gtmQuestion: "gtm question_7",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Yes",
                value: "18",
                gtmAnswer: "yes"
            },
            {
                label: "No",
                value: "19",
                gtmAnswer: "no"
            }
        ]
    },
    question_8: {
        questionId: "8",
        question: "Q8 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        gtmQuestion: "gtm question_8",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Yes",
                value: "20",
                gtmAnswer: "yes"
            },
            {
                label: "No",
                value: "21",
                gtmAnswer: "no"
            }
        ]
    },
    question_9: {
        questionId: "9",
        question: "Q9 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        gtmQuestion: "gtm question_9",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Yes",
                value: "22",
                gtmAnswer: "yes"
            },
            {
                label: "No",
                value: "23",
                gtmAnswer: "no"
            }
        ]
    },
    question_10: {
        questionId: "10",
        question: "Q10 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        gtm_trigger:true,
        clickevent:'clickslide',
        gtmQuestion: "gtm question_10",
        answers: [
            {
                label: "Yes",
                value: "24",
                gtmAnswer: "yes"
            },
            {
                label: "No",
                value: "25",
                gtmAnswer: "no"
            }
        ]
    },
    question_11: {
        questionId: '11',
        gtmQuestion: "gtm question_11",
        question: "Q11 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Option-1",
                value: "26",
                gtmAnswer: 'Option-1',
            },
            {
                label: "Option-2",
                value: "27",
                gtmAnswer: 'Option-2',
            },
            {
                label: "Option-3",
                value: "28",
                gtmAnswer: 'Option-3',
            },
            {
                label: "Option-4",
                value: "29",
                gtmAnswer: 'Option-4',
            },
            {
                label: "Option-5",
                value: "30",
                gtmAnswer: 'Option-5',
            },
            {
                label: "Option-6",
                value: "31",
                gtmAnswer: 'Option-6',
            },
            {
                label: "Option-7",
                value: "32",
                gtmAnswer: 'Option-7',
            },
            {
                label: "Option-8",
                value: "33",
                gtmAnswer: 'Option-8',
            },
            {
                label: "Option-9",
                value: "34",
                gtmAnswer: 'Option-9',
            },
            {
                label: "Option-10",
                value: "35",
                gtmAnswer: 'Option-10',
            },
            {
                label: "Option-11",
                value: "36",
                gtmAnswer: 'Option-11',
            },
            {
                label: "Option-12",
                value: "37",
                gtmAnswer: 'Option-12',
            },
            {
                label: "Option-13",
                value: "38",
                gtmAnswer: 'Option-13',
            },
            {
                label: "Option-14",
                value: "39",
                gtmAnswer: 'Option-14',
            },
        ],
    },
    question_12: {
        questionId: "12",
        question: "Q12 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        gtmQuestion: "gtm question_12",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Yes",
                value: "40",
                gtmAnswer: "yes"
            },
            {
                label: "No",
                value: "41",
                gtmAnswer: "no"
            }
        ]
    },
    questinnare7Sub1: {
        question: "Q-sub-7-1 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        questionId: 'sub1',
        gtmQuestion: "get question_7 sub_1",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Option-1",
                value: "Option-1",
                gtmAnswer: "Option-1"
            },
            {
                label: "Option-2",
                value: "Option-2",
                gtmAnswer: "Option-2"
            },
            {
                label: "Option-3",
                value: "Option-3",
                gtmAnswer: "Option-3"
            },
            {
                label: "Option-4",
                value: "Option-4",
                gtmAnswer: "Option-4"
            },
            {
                label: "Option-5",
                value: "Option-5",
                gtmAnswer: "Option-5"
            }
        ]
    },
    questinnare7Sub2: {
        question: "Q-sub-7-2 Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        questionId: 'sub2',
        gtmQuestion: "gtm question_7 sub_2",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Yes",
                value: "yes",
                gtmAnswer: "yes"
            },
            {
                label: "No",
                value: "no",
                gtmAnswer: "no"
            }
        ]
    },
    question_68: {
        question: "Q68 Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        questionId: '68',
        gtmQuestion: "gtm question_68",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Option-1",
                value: "a",
                gtmAnswer: "Option-1"
            },
            {
                label: "Option-2",
                value: "b",
                gtmAnswer: "Option-2"
            },
            {
                label: "Option-3",
                value: "c",
                gtmAnswer: "Option-3"
            },
            {
                label: "Option-4",
                value: "d",
                gtmAnswer: "Option-4"
            }
        ]
    },
}
const questionnaire_S_GYR_V1 = {
    question_1: {
        questionId: 1,
        question: "Have you bought a car on finance with PCP or HP before 28 January 2021?",
        subQuestion:"Please only tell us about finance taken out in your own name.",
        gtmQuestion: "date",
        gtm_trigger:true,
        clickevent:'slideclick',
        answers: [
            {
                label: "Yes",
                value: "1",
                gtmAnswer: 'yes',
            },
            {
                label: "No",
                value: "2",
                gtmAnswer: 'no'
            }
        ],

    },
    question_2: {
        questionId: 2,
        question: "Have you ever been known by a different name?",
        subQuestion:"",
        gtmQuestion: "different name",
        gtm_trigger:false,
        clickevent:'slideclick',
        answers: [
            {
                label: "Yes",
                value: "3",
                gtmAnswer: 'yes',
            },
            {
                label: "No",
                value: "4",
                gtmAnswer: 'no'
            }
        ],

    },
    question_3: {
        questionId: 3,
        question: "Did you live at your Current Address when you took out the finance?",
        subQuestion:"",
        gtmQuestion: "finance address",
        gtm_trigger:false,
        clickevent:'slideclick',
        answers: [
            {
                label: "Yes",
                value: "5",
                gtmAnswer: 'yes',
            },
            {
                label: "No",
                value: "6",
                gtmAnswer: 'no'
            }
        ],

    },
    question_4: {
        questionId: 4,
        question: "Have you lived at any other addresses in the last 10 years?",
        subQuestion:"",
        gtmQuestion: "gtm question_1",
        gtm_trigger:false,
        clickevent:'clickslide',
        answers: [
            {
                label: "Yes",
                value: "7",
                gtmAnswer: 'yes',
            },
            {
                label: "No",
                value: "8",
                gtmAnswer: 'no'
            }
        ],

    }
}
export {
    questinnare1,
    questinnare2,
    questinnare3,
    questinnare4,
    questinnare5,
    questinnare6,
    questinnare7,
    questinnare8,
    Salutation,
    questinnare7Sub1,
    questinnare7Sub2,
    questinnare11,
    questinnare12,
    questinnare9,
    questinnare10,
    questionnaire,
    questionnaire_S_GYR_V1
};
