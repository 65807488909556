import React, { useState } from 'react';
import { DobYear, DobMonthNumber, DobDay } from '../Constants/Constants';
import SelectBox from '../Components/UI/SelectBox';


const DateOfBirth = (props) => {
  const [dobValues, setDobValues] = useState({ DobDay: '', DobMonth: '', DobYear: '' });
  const updateDobValue = (e) => {
    const values = props.getValues();
    setDobValues(prevValues => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
    if (values.DobDay != '' && values.DobMonth != '' && values.DobYear != '') {
      var date = new Date(parseInt(values.DobYear), parseInt(values.DobMonth) - 1, parseInt(values.DobDay));
      var dateStatus = parseInt(values.DobMonth) == date.getMonth() + 1;

      if (dateStatus == false) {
        props.setError(e.target.name, {
          type: "manual",
          message: "Please Enter Valid Date",
        });
        setDobValues(prevValues => ({
          ...prevValues,
          [e.target.name]: '',
        }));
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-12 mb-3">
          <label className="m-0 top_line">Date</label>
          <SelectBox
            placeholderOption="DD"
            className="required"
            OptionValue={DobDay}
            name="DobDay"
            id="DobDay"
            onChange={(e) => { props.clearErrors("DobDay"); updateDobValue(e) }}
            myRef={props.validation({
              required: "Please Select Date"
            })}
            value={dobValues.DobDay}
            validationMsg={props.validationMsg.DobDay && props.validationMsg.DobDay.message}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-12 mb-3">
          <label className="m-0 top_line">Month</label>
          <SelectBox
            placeholderOption="MM"
            className="required"
            OptionValue={DobMonthNumber}
            name="DobMonth"
            id="DobMonth"
            onChange={(e) => { props.clearErrors("DobMonth"); updateDobValue(e) }}
            myRef={props.validation({
              required: "Please Select Month"
            })}
            value={dobValues.DobMonth}
            validationMsg={props.validationMsg.DobMonth && props.validationMsg.DobMonth.message}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-12 mb-3">
          <label className="m-0 top_line">Year</label>
          <SelectBox
            placeholderOption="YYYY"
            className="required"
            OptionValue={DobYear}
            name="DobYear"
            id="DobYear"
            value={dobValues.DobYear}
            onChange={(e) => { props.clearErrors("DobYear"); updateDobValue(e) }}
            myRef={props.validation({
              required: "Please Select Year"
            })}
            validationMsg={props.validationMsg.DobYear && props.validationMsg.DobYear.message}
          />
        </div>
      </div>
    </>
  )
}

export { DateOfBirth };