import React, { useState } from "react";
import ProgressBar from "./ProgressBar";
import { Lender } from "../../../../Constants/Lender";
import CheckBox from "../../../UI/CheckBox";
import Button from "../../../UI/Button";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const LenderSelection = (props) => {
  const [additionalLenders, setAdditionalLenders] = useState(false);
  const [selectedLender, setSelectedLender] = useState([]);
  const [validationError, setValidationError] = useState({ errorStatus: false, message: '' });
  const clickEvent = (e, lenderId) => {

    if (!selectedLender.includes(lenderId) && selectedLender.length >= 4) {
      setValidationError({ errorStatus: true, message: 'You can only select up to 4 lenders' });
      props.scrolltobtn.current.scrollIntoView({
        behavior: "smooth",
        block: 'end'
      });
      return false;
    }
    const updatedData = selectedLender.includes(lenderId)
      ? selectedLender.filter(id => id !== lenderId)
      : [...selectedLender, lenderId];
    setSelectedLender(updatedData);
    setValidationError({ errorStatus: updatedData.length == 0, message: 'Please Select Your Finance provider' });

  };
  const LenderSection1 = [];
  const LenderSection2 = [];
  Lender.map((value, index) => {
    const element = (
      <div className="padding16p" key={`${index}`}>

        <div className={index > 11 ? 'violot-button violot-button2' : 'violot-button'}>
          <CheckBox
            className={`test test-1`}
            value={value.value}
            dataId={`lender_${index}`}
            name='lender'
            onChange={(e) => { clickEvent(e, value.value) }}
            labelText={value.label}
            labelClassName={`selected-false mb-0 border-0`}
            checked={selectedLender.includes(value.value) ? true : false}
            CheckBoxText={``}
          />
        </div>

      </div>
      // <li key={`${index}`}>
      //   <CheckBox
      //     className={`circle`}
      //     value={value.value}
      //     dataId={`lender_${index}`}
      //     name='lender'
      //     onChange={(e) => { clickEvent(e, value.value) }}
      //     labelText={value.label}
      //     labelClassName={index > 11 ? 'name lend_2' : 'name'}
      //     checked={selectedLender.includes(value.value) ? true : false}
      //     CheckBoxText='<span className="circle"></span>'
      //   />
      // </li>
    )

    if (index > 11) {
      LenderSection2.push(element);
    } else {
      LenderSection1.push(element);
    }
  });
  const handleLenders = (e) => {
    if (selectedLender.length == 0) {
      setValidationError({ errorStatus: true, message: 'Please Select Your Finance provider' });
    } else {
      localStorage.setItem('banks', JSON.stringify(selectedLender));
      setValidationError({ errorStatus: false, message: '' });
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'finance provider',
        answer: '',
      });
      props.slideChange(e);
    }
  }

  return (
    <>
      <div className={`form-view animate__animated animate__fadeInUp animate__delay-.5s ${props.className}`} id="step-3" >
        <ProgressBar step={props.step} load={props.loadPercent} />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
          <label className="mb-2">Who was your finance provider? <em>*</em>
            <span>Many of our clients have multiple cars financed through multiple lenders.</span>
            <i>(You can choose up to 4 lenders)</i>
          </label>
          {/* <ul className="lender-list ps-0 mx-3 mt-4 animate__animated animate__fadeInUp animate__delay-.5s"> */}
          {LenderSection1}
          {/* </ul> */}

          {!additionalLenders && <div className="mx-3 text-white fw-bold more-lenders animate__animated animate__fadeInUp animate__delay-.5s" id="moreLenders" onClick={() => { setAdditionalLenders(true) }}><u>View more lenders ({LenderSection2.length})</u></div>}

          {additionalLenders && <ul className="lender-list lender-list-two ps-0 mt-2 animate__animated animate__fadeInUp" >
            {LenderSection2}
          </ul>

          }

        </div>
        {validationError.errorStatus && (
          <span
            className="error_msg "
            id="txtEmail_err"
          >
            <label id="error" className="error" > {validationError.message}</label>
          </span>
        )}
        <div className="form-sub_btn" ref={props.scrolltobtn}>
          <Button
            type="button"
            name={`bank`}
            id={`back_bank`}
            className="msf-nav-button next button color-1"
            buttonText="Previous"
            onClick={props.backClick}
          />
          <Button
            type="button"
            name={`bank`}
            id={`next_bank`}
            className="msf-nav-button next button color-1"
            buttonText="Next"
            onClick={handleLenders}
          />
        </div>
      </div>
    </>
  )
}

export default LenderSelection;