import React, { useEffect, useState, useRef, useReducer, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import PersonalDetails from "../Includes/Layouts/S_GYR_V1/PersonalDetails";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import * as EnvConstants from "../../Constants/EnvConstants";
import { questionnaire_S_GYR_V1 } from "../../Constants/Questions";
import shieldSmall from "../../assets/img/S_GYR_V1/shield-small.png";
import tick from "../../assets/img/S_GYR_V1/tick.png";
import RadioQuestionnaire from "../Includes/Layouts/S_GYR_V1/RadioQuestionnaire";
import LenderSelection from "../Includes/Layouts/S_GYR_V1/LenderSelection";
import PostCode from "../Includes/Layouts/S_GYR_V1/PostCode";
import Signature_Sygrv1 from "../Includes/Layouts/S_GYR_V1/Signature_Sygrv1";

const FormS_GYR_V1 = (props) => {

  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide"
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "question_1") {
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "bank") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "personal_details") {
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "postcode") {
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case 'previousSlides': {
        if (action.payload.previousSlide.slide == 'bank') {
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'personal_details') {
          return {
            ...state,
            showSlide2: "show",
            showSlide3: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'postcode') {
          return {
            ...state,
            showSlide3: "show",
            showSlide4: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'signature') {
          return {
            ...state,
            showSlide4: "show",
            showSlide5: "hide"
          }
        }
        else {
          return {
            ...state
          }
        }
      }
      default: {
      }
    }
  };
  const splitForm = useRef(null);
  const scrolltodiv = useRef([]);
  const scrolltobtnS2 = useRef([]);
  const scrolltobtnS3 = useRef([]);
  const scrolltobtnS4 = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const { register, errors, trigger, setError, clearErrors, getValues, reset } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const slideChange = (e) => {
    if (e.target.name != 'signature') {
      setTimeout(() => {
        scrolltodiv.current.scrollIntoView({
          behavior: "smooth",
          top: 0
        });
      }, 50);
      setClickedSlide({ slide: e.target.name });
    }
    else {
      formSubmit();
    }
  };

  const previousSlideChange = (e) => {
    setTimeout(() => {
      scrolltodiv.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: 'previousSlides', payload: { previousSlide } });
    }
  }, [previousSlide]);

  const formSubmit = async () => {
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }
    formData.page_name = 'S_GYR_V1';
    formData.pCode_manual = form['pCode_EntryType'].value;
    formData.signature_data = localStorage.getItem('signatureData')
    formData.bank = JSON.parse(localStorage.getItem('banks'));
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem('formData', JSON.stringify(formData));
      localStorage.setItem('queryString', queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        'split_form_submit',
        'S_GYR_V1',
        queryString,
        visitorParameters.data
      )
      // if (formSUbmitResult.data.status === "Success") {

      if (queryString != null) {
        console.log(queryString);
        if (queryString.indexOf("&" + field + "=") !== -1) {
          console.log("s");
          if (EnvConstants.AppConversionPixel === 'true') {
            console.log("if fb");
            history.push("/fbpixel?split_name=" + props.splitName + "&next_page=" + props.nextPage); // fb fixel firing
          } else {
            console.log("else fb");
            window.scrollTo(0, 0);
            history.push(
              "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
            );
          }
        } else {
          console.log("another else fb");
          window.scrollTo(0, 0);
          history.push(
            "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
          );
        }
      } else {

        console.log("laset else  fb");
        window.scrollTo(0, 0);
        history.push(
          "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
        );
      }
      // }
    }
  };

  return (
    <>


      <div className="row steps_cont">
        <div className="col-12">
          <h1 className="cs_hero_title mb-2 animate__animated animate__fadeInUp animate__delay-.5s">Let's get your claim started ...</h1>
          <p className="cs_hero_subtitle">
            <span><img src={tick} alt="" />No Win No Fee</span>
            <span className="ps-3"><img src={shieldSmall} alt="" />Privacy Protected</span>
          </p>
          <form
            ref={splitForm}
            name="split_form"
            id="user_form"
            method="POST"
            autoComplete="off"
            className="main_form form_display"
          >
            <div className="form-content" ref={scrolltodiv}>
              <RadioQuestionnaire
                className={`${state.showSlide1}`}
                //textHeading="Are you currently enrolled in Medicare?"
                validation={register}
                slideChange={slideChange}
                getValues={getValues}
                gtm_question={questionnaire_S_GYR_V1.question_1}
                step="1"
                loadPercent="load"
              />
              <LenderSelection
                className={`${state.showSlide2}`}
                validation={register}
                slideChange={slideChange}
                getValues={getValues}
                step="2"
                loadPercent="load1"
                backClick={previousSlideChange}
                scrolltobtn={scrolltobtnS2}
              />
              <PersonalDetails
                className={`${state.showSlide3}`}
                validation={register}
                slideChange={slideChange}
                getValues={getValues}
                step="3"
                loadPercent="load2"
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                splitForm={splitForm}
                backClick={previousSlideChange}
                setError={setError}
                reset={reset}
                scrolltobtn={scrolltobtnS3}
                gtm_question={questionnaire_S_GYR_V1.question_2}
              />
              <PostCode
                validation={register}
                validationMsg={errors}
                clearErrors={clearErrors}
                setError={setError}
                slideChange={slideChange}
                trigger={trigger}
                className={`${state.showSlide4}`}
                backClick={previousSlideChange}
                splitForm={splitForm}
                getValues={getValues}
                reset={reset}
                step="4"
                loadPercent="load3"
                scrolltobtn={scrolltobtnS4}
                gtm_question={questionnaire_S_GYR_V1.question_3}
              />
              <Signature_Sygrv1
                slideChange={slideChange}
                backClick={previousSlideChange}
                className={`${state.showSlide5}`}
                step="5"
                loadPercent="load4"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormS_GYR_V1;
