import React from "react";
import Accordion from 'react-bootstrap/Accordion';
const SplitFAQ = (props) => {
  const FaqString = props.faq.map((faqs, index) => {
    return (
      <div className="accordion-list accordion-item" key={index}>
        <Accordion.Item className="accordion-item" eventKey={index}>
          <Accordion.Header className="faq__accordion-title">
            {faqs.heading}
          </Accordion.Header>
          <Accordion.Collapse eventKey={index} id="accor-1" className="accordion-collapse collapse">
            <Accordion.Body className="accordion-body ">
              <p>
                <span dangerouslySetInnerHTML={{ __html: faqs.content }}></span>
              </p>
            </Accordion.Body>
          </Accordion.Collapse>
        </Accordion.Item>
      </div>
    );
  });
  return (
    // <div className="splitFaq">
    <div className="sec_3Disgn splitFaq" id="faq_sec">
      <div className="container">
        <div className="row">
          <div className="col-12 acco_desin">
            <section className="accordion">
              <Accordion className="accordion-section">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <h2 className="text-center mb-4"><b>{props.accordionTitle}</b></h2>
                      {FaqString}
                    </div>
                  </div>
                </div>
              </Accordion>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitFAQ;
