import React from "react";
import GTMDataLayer from "../../Utility/GTMDataLayer";
const RadioButton = ({
  id,
  value,
  name,
  className,
  labelName,
  labelClassName,
  onClick,
  validation,
  checked,
  gtm_question,
  gtm_trigger,
  gtm_answer,
  clickevent,
  onChange
}) => {
  const handleClick = (e) => {
    if (e.target.dataset.gtm_trigger) {
      GTMDataLayer({
        clickEvent: e.target.dataset.clickevent,
        question: e.target.dataset.gtm_question,
        answer: e.target.dataset.gtm_answer,
      });
    }
  }
  return (
    <>
      <label htmlFor={id} className={labelClassName}>
        <input
          type="radio"
          className={className}
          name={name}
          id={id}
          value={value}
          onChange={(e)=>{handleClick(e)}}
          ref={validation}
          checked={checked}
          data-gtm_question={gtm_question}
          data-gtm_trigger={gtm_trigger}
          data-gtm_answer={gtm_answer}
          data-clickevent={clickevent}
          onClick={onClick}
        />
        {labelName}
      </label>
    </>
  );
};
export default RadioButton;
