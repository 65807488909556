// import { lazy } from 'react';
// const Split_1 =  import('./Components/Pages/Splits/Split_1');

// export {
//     Split_1
// }

import React, { lazy } from 'react';

const S_GYR_V1 = lazy(() => import('./Components/Pages/Splits/S_GYR_V1'));

const AppSplitList = (props) => {

    const SplitListMap = {
        'S_GYR_V1': S_GYR_V1
    }
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;