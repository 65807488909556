import React, { useState } from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import Footer from "../../Includes/Layouts/S_GYR_V1/Footer";
import Header from "../../Includes/Layouts/S_GYR_V1/Header";
import FbViewPixel from '../../../Utility/FbViewPixel';
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import "../../../assets/css/S_GYR_V1.scss";
import bg3d from "../../../assets/img/S_GYR_V1/bg_3d.webp";
import layer01 from "../../../assets/img/S_GYR_V1/layer01.webp";
import layer03 from "../../../assets/img/S_GYR_V1/layer3.webp";
import carRefund from "../../../assets/img/S_GYR_V1/car-refund.png";
import poundPic from "../../../assets/img/S_GYR_V1/pound-pic.png";
import metabolism from "../../../assets/img/S_GYR_V1/metabolism.png";
import SplitFAQ from "../../Includes/Layouts/S_GYR_V1/SplitFAQ";
import { SplitFaq_S_GYR_V1 } from "../../../Constants/SplitFaq";
import FormS_GYR_V1 from "../../Forms/FormS_GYR_V1";
import Button from "../../UI/Button";

const S_GYR_V1 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('S_GYR_V1', 'S_GYR_V1');
  console.log("i am here" + DynamicRouteNextPage);
  const [formLoad, setFormLoad] = useState(false);
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="S_GYR_V1" />
      <FbViewPixel />
      <div className="__GYRhome">
        <Header />
        <section className="cs_hero cs_style_1">
          <div className="cs_hero_wrap cs_bg_filed">
            <div className="container">
              {!formLoad && <div className="row pb-5 pad_none">
                <div className="cs_hero_text col-lg-6 col-12" id="step-1">
                  <h1 className="cs_hero_title animate__animated animate__fadeInUp animate__delay-.5s">Bought a car on finance before 28 January 2021? You could be owed a refund.</h1>
                  <p className="cs_hero_subtitle lit_sm animate__animated animate__fadeInUp animate__delay-.5s">
                    The Financial Conduct Authority (FCA) has found that hidden car finance commissions have potentially cost customers £1,100 or more in additional interest charges.
                  </p>
                  <div className="pre_cs_btn_dialNum position-relative animate__animated animate__fadeInUp animate__delay-.5s">
                    <span className="main-heading-group">
                      <Button
                        className="main-heading-button zoom-in-zoom-out custom_clickBtn btn-vp"
                        id="startClaim"
                        onClick={(e) => { setFormLoad(true) }}
                        buttonText="Start your claim"
                        clickevent="slideclick"
                        gtm_question="start your claim"
                        gtm_answer=""
                        gtm_trigger="true"
                      />
                    </span>
                  </div>
                </div>
                <div className="img_float col-lg-6 col-12 text-center" id="planeAnim">
                  <div className="parallax mx-auto ms-md-0 me-md-n5" style={{ maxWidth: "675px" }}>
                    <div className="parallax-layer fadein zindex-2" data-depth="0.1">
                      <img src={bg3d} alt="Hero" className="cs_hero_img fadein" />
                    </div>
                    <div className="parallax-layer fadein position-absolute zindex-3" data-depth="0.25">
                      <img src={layer01} alt="Hero" className="cs_hero_img fadein" />
                    </div>
                    <div className="parallax-layer position-absolute fadein" data-depth="-0.15">
                      <img src={layer03} alt="Hero" className="cs_hero_img fadein" />
                    </div>
                  </div>
                </div>
              </div>}
              {formLoad && <FormS_GYR_V1  nextPage={DynamicRouteNextPage} splitName={DynamicRouteSplitName}/>}
              <div className="cs_hero_info_wrap cs_shadow_1 rounded-4 row">
                <div className="container">
                  <div className="card">
                    <div className="box">
                      <div className="content text-center">
                        <img className="card-png-icon" src={carRefund} />
                        <h3>Mis-sold car finance claim specialists</h3>
                        <p className="m-0">We specialise in car finance mis-selling claims and are highly familiar with the car finance market.</p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="box">
                      <div className="content">
                        <img className="card-png-icon" src={poundPic} />
                        <h3>No win, no fee</h3>
                        <p className="m-0">We provide our service on a ‘no win, no fee’ basis – meaning there is no charge if your claim is unsuccessful.</p>
                        <p className="m-0">If your claim is successful, our fee is 25% plus VAT of any compensation amount.</p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="box">
                      <div className="content">
                        <img className="card-png-icon" src={metabolism} />
                        <h3>Fully regulated law firm</h3>
                        <p className="m-0">We are authorised and regulated by the Solicitors Regulation Authority.</p>
                        <p className="m-0">You can be assured that your claim is in safe hands.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </section>

        <SplitFAQ faq={SplitFaq_S_GYR_V1} accordionTitle="Mis-sold Car Finance FAQs" />
        <Footer />
      </div>
    </>
  );
};

export default S_GYR_V1;
