import React from 'react';
import logoWhite from ".././../../../assets/img/S_GYR_V1/logo-white.png"

const Header = () => {
  return (
    <>
      <section className="header_stk ticker-wrapper">
        <div className="ticker-wrapper__first-half">
          <p>Brought to you by Stephens Wilmot Solicitors</p>
        </div>
      </section>

      <header>
        <div className="container top_notch">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 nav_barACA">
              <img src={logoWhite} alt="" />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;