export const split_Flow = 
    {
        default:{
            1:'Split_1', 
            2:'Signature',
            3:'Proceed',
            4:'Thankyou'
        },
        Split_1:{
            1:'Split_1', 
            2:'signature',
            3:'proceed',
            4:'questionnaire',
            5:'id-upload',
            6:'splash-page',
            7:'preview',
            8:'thankyou'
        },
        Split_2:{
            1:'Split_2', 
            2:'proceed',
            3:'id-upload',
            4:'splash-page',
            5:'preview',
            6:'signature-page',
            7:'thankyou'
        },
        followup:{
            1:'thankyou',
        },
        S_GYR_V1:{
            1:'S_GYR_V1',
            2:'thankyou'
        }
    }