import React from "react";

const CheckBox = ({
  value,
  CheckBoxText,
  name,
  checked,
  onChange,
  dataId,
  className,
  labelClassName,
  labelText,
  onClick,
}) => {
  return (
    <>
      <input
        type="checkbox"
        className={className}
        value={value}
        id={dataId}
        name={name}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
      />
      <label className={labelClassName} htmlFor={dataId} >
        {labelText}
      </label>
    </>
  );
};
export default CheckBox;
