const slideValidation = async (fields, action) => {
    for (const item of fields) {
        if (!await action(item)) {
            var elementCheck = document.getElementById(item);
            if (elementCheck) { elementCheck.focus(); }
            return item;
        }
    }
    return 'Success';
};

const numberValidation = (evt) => {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;console.log(charCode);
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          evt.preventDefault();
          return false;
        }
        else {
          return true;
        }
}

const nameValidate = (evt) => {
    const char = String.fromCharCode(evt.charCode);
    if (!/^[a-zA-Z\s]*$/.test(char)) {
      evt.preventDefault();
      return false;
    }
    evt.target.value = evt.target.value.trimStart();
}
export { slideValidation, numberValidation, nameValidate }