import React, { useReducer, useState, useContext } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import Anchor from "../../../UI/Anchor";
import InputButton from "../../../UI/InputButton";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Button from "../../../UI/Button";
import returnImg from "../../../../assets/img/S_GYR_V1/return.png";
import editImg from "../../../../assets/img/S_GYR_V1/edit.png";
import SelectBox from "../../../UI/SelectBox";


const initialState = {
    lookUp: true,
    nextClick: false,
    checkSelect: false,
    getDetails: [],
    getAddress: [],
    showManualAddress: "hide",
    getPostcode: "#",
    clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
    switch (action.type) {
        case "validationSucess": {
            return {
                ...state,
                lookUp: action.payload.lookup,
                nextClick: action.payload.next_click,
                getPostcode: action.payload.postcode,
                getAddress: action.payload.get_address,
                checkSelect: action.payload.check_select,
                showManualAddress: action.payload.manual_address,
            };
        }
        case "getExtraAddressDetails": {
            return {
                ...state,
                getDetails: action.payload.get_details,
                showManualAddress: action.payload.manual_address,
            };
        }
        case "showManualDiv": {
            return {
                ...state,
                showManualAddress: "show",
                lookUp: false,
                nextClick: true,
                checkSelect: false,
                clickManualLink: action.payload.manual_click,
            };
        }
        case "resetaddress": {
            return {
                ...state,
                getDetails: [],
                getAddress: [],
                showManualAddress: "hide",
                lookUp: true,
                nextClick: false,
                checkSelect: false,
            };
        }
        default:
            return state;
    }
};
const PreviousPostCode = ({
    validation,
    validationMsg,
    clearErrors,
    setError,
    slideChange,
    trigger,
    className,
    backClick,
    splitForm,
    getValues,
    preCount,
    title,
    scrolltobtn
}) => {
    const { getPostcodeAddress, getSplitPostcodeAddress, isLoading } =
        usePostCodeAddress();
    const [state, dispatch] = useReducer(postcodeReducer, initialState);
    const [postCodeAddress, setPostCodeAddress] = useState([]);
    const [postCodeEntry, setPostCodeEntry] = useState("0");
    const { visitorParameters } = useContext(VisitorParamsContext);
    const [manualAddress, setManualAddress] = useState(false);
    const checkValidation = async (e) => {

        e.preventDefault();
        dispatch({
            type: "resetaddress",
            get_address: [],
        });
        const txtPrevPostCode = await trigger(`txtPrevPostCode_${preCount}`);
        if (txtPrevPostCode) {
            await getPostcodeAddress(e.target.value, visitorParameters.visitor_parameters.uuid).then(res => {
                if (res.data.status === "0") {
                    setError(`txtPrevPostCode_${preCount}`, {
                        type: "manual",
                        message: "Please Enter Valid Postcode",
                    });
                }
                if (res.data.length > 0) {
                    dispatch({
                        type: "validationSucess",
                        payload: {
                            lookup: false,
                            next_click: true,
                            postcode: e.target.value,
                            get_address: res.data,
                            check_select: true,
                            manual_address: "hide",
                        },
                    });
                }
            }).catch(
                function () {
                    setError(`txtPrevPostCode_${preCount}`, {
                        type: "manual",
                        message: "Please Enter Valid Postcode",
                    });
                }
            );
        }
    };
    const getValue = async (e) => {
        e.preventDefault();
        setManualAddress(false);
        clearErrors([
            `txtPrevHouseNumber_${preCount}`,
            `txtPrevAddress3_${preCount}`,
            `txtPrevCounty_${preCount}`,
            `txtPrevTown_${preCount}`,
            `txtPrevCountry_${preCount}`,
        ]);
        if (e.target.value != "") {
            const getData = await getSplitPostcodeAddress(
                e.target.value,
                state.getPostcode,
                visitorParameters.visitor_parameters.uuid
            );
            dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: getData.data, manual_address: "show" },
            });
            setPostCodeAddress(getData.data);
            clearErrors([
                `txtPrevHouseNumber_${preCount}`,
                `txtPrevAddress3_${preCount}`,
                `txtPrevCounty_${preCount}`,
                `txtPrevTown_${preCount}`,
                `txtPrevCountry_${preCount}`,
            ]);
            setTimeout(() => {
                scrolltobtn.current.scrollIntoView({
                  behavior: "smooth",
                  block: 'end'
                });
              }, 50);
        }
        else {
            dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: [], manual_address: "hide" },
            });
            setPostCodeAddress([]);
        }
    };

    const lookupValidation = async (e) => {
        await trigger(`txtPrevPostCode_${preCount}`);
    };

    const checkPostCodeEntry = () => {
        const addressLine1 = document.getElementById(`txtPrevHouseNumber_${preCount}`).value;
        const addressLine2 = document.getElementById(`txtPrevAddress3_${preCount}`).value;
        const town = document.getElementById(`txtPrevTown_${preCount}`).value;
        const county = document.getElementById(`txtPrevCounty_${preCount}`).value;
        const country = document.getElementById(`txtPrevCountry_${preCount}`).value;

        if (
            postCodeAddress.length === 0 ||
            postCodeAddress.Country !== country ||
            postCodeAddress.County !== county ||
            postCodeAddress.Line1 !== addressLine1 ||
            postCodeAddress.Line2 !== addressLine2 ||
            postCodeAddress.Town !== town
        ) {
            setPostCodeEntry("1");
        }
    };

    const addressValidation = async (e) => {
        let errorFlag = 0;
        const txtPrevPostCode = await trigger(`txtPrevPostCode_${preCount}`);
        var manual_txtPrevHouseNumber;
        var manual_txtPrevTown;
        var manual_txtCountry;
        var txtAddress;
        if (txtPrevPostCode) {
            manual_txtPrevHouseNumber = await trigger(`txtPrevHouseNumber_${preCount}`);
            txtAddress = await trigger("address1");
        } else {
            document.getElementById(`txtPrevPostCode_${preCount}`).focus();
            return false;
        }
        if (manual_txtPrevHouseNumber) {
            manual_txtPrevTown = await trigger(`txtPrevTown_${preCount}`);
        } else {
            return false;
        }
        if (manual_txtPrevTown) {
            manual_txtCountry = await trigger(`txtPrevCountry_${preCount}`);
        } else {
            return false;
        }
        if (
            !txtPrevPostCode ||
            !manual_txtPrevHouseNumber ||
            !manual_txtPrevTown ||
            !manual_txtCountry
        ) {
            errorFlag = 1;
        }

        if (state.clickManualLink === 0) {
            const address1 = await trigger("address1");
            if (!address1) {
                errorFlag = 1;
            }
        }
        if (errorFlag === 0) {
            checkPostCodeEntry();
            slideChange(e);
        } else {
            return false;
        }
    };
    const reSearchPostcode = () => {
        dispatch({ type: "resetaddress", get_address: [], });
        setManualAddress(false);
        document.getElementById(`txtPrevPostCode_${preCount}`).value = '';
    }
    const HiddenFields = () => (
        <>
            <HiddenField
                name="txtUdprn"
                inputId="txtUdprn"
                inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
            />
            <HiddenField
                name="txtDeliveryPointSuffix"
                inputId="txtDeliveryPointSuffix"
                inputValue={
                    state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
                }
            />
            <HiddenField
                name="txtPz_mailsort"
                inputId="txtPz_mailsort"
                inputValue={
                    state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
                }
            />
            {/* <HiddenField
                name="txtStreet"
                inputId="txtStreet"
                inputValue={
                    state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
                }
            /> */}
        </>
    );
    return (
        <>
            <HiddenField
                name="pCode_EntryType"
                inputId="pCode_EntryType"
                inputValue={postCodeEntry}
            />
            <div className={`field animate__animated animate__fadeInUp animate__delay-.5s preAdd ${className}`}>
                <label className="mb-2">{title} <em>*</em></label>
                <div id="lookup_field" className="row">
                    <div className="col-lg-8 col-md-8 col-12 pb-3">
                        <AddressTextField
                            type="text"
                            placeholder="Enter Your Postcode"
                            name={`txtPrevPostCode_${preCount}`}
                            id={`txtPrevPostCode_${preCount}`}
                            className="wpforms-field-address-address1"
                            dataId={`txtPrevPostCode_${preCount}`}
                            onBlur={checkValidation}
                            autoComplete="off"
                            onChange={() => clearErrors(`txtPrevPostCode_${preCount}`)}
                            validation={validation({
                                required: "Please Enter Postcode",
                                pattern: {
                                    value: /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                                    message: "Please Enter a Valid Postcode",
                                },
                                minLength: {
                                    value: 5,
                                    message: "Please Enter a Valid Postcode",
                                },
                            })}
                            validationMsg={
                                validationMsg[`txtPrevPostCode_${preCount}`] && validationMsg[`txtPrevPostCode_${preCount}`].message
                            }
                        />
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 pb-3">
                        <Button
                            type="button"
                            name={`find_address`}
                            id={`idpc_button1`}
                            className="msf-nav-button next button color-1"
                            buttonText={isLoading ? "Searching..." : "Find Address"}
                            onClick={lookupValidation}
                        />
                    </div>
                    {state.checkSelect && <div className="col-lg-8 col-md-8 col-12 pb-3 idpc_dropdown1" id="idpc_address1" >
                        <AddressSelectBox
                            className="valid"
                            OptionValue={state.getAddress}
                            name={`txtPrevAddress1_${preCount}`}
                            id={`txtPrevAddress1_${preCount}`}
                            onChange={getValue}
                            myRef={validation({ required: "Please select address" })}
                            validationMsg={
                                validationMsg[`txtPrevAddress1_${preCount}`] && validationMsg[`txtPrevAddress1_${preCount}`].message
                            }
                        />
                    </div>}
                </div>
                {!manualAddress && <><div className={`Mod_detail py-3 px-3 animate__animated animate__fadeInUp animate__delay-.5s ${state.showManualAddress}`}>
                    <p className="ADD_clr_typ">
                        {state.getDetails.line_1 && (<>{state.getDetails.line_1}<br /></>)}
                        {state.getDetails.line_2 && (<>{state.getDetails.line_2}<br /></>)}
                        {state.getDetails.line_3 && (<>{state.getDetails.line_3}<br /></>)}
                        {state.getDetails.county && (<>{state.getDetails.county}<br /></>)}
                        {state.getDetails.town && (<>{state.getDetails.town}<br /></>)}
                        {state.getDetails.country && (<>{state.getDetails.country}<br /></>)}
                    </p>
                    <p className="ADD_na_top mb-0">
                        {state.getDetails.postcode}
                    </p>
                </div>

                    <div className={`return_back ${state.showManualAddress}`}>
                        <button type="button" className="mb-0 link-btn pcode_btn" onClick={() => { reSearchPostcode() }}><img src={returnImg} alt="" />Search</button>
                        <button type="button" className="mb-0 link-btn pcode_btn float-right" onClick={(e) => { setManualAddress(true) }}><img src={editImg} alt="" />Edit</button>
                    </div></>}

            </div>
            <div className={`slide8_add row ${manualAddress ? '' : 'd-none'}`}>
                <div className={`col-lg-12 form-group required-field adj_width mb-1`}>
                    <AddressTextField
                        type="text"
                        placeholder="Address Line 1 *"
                        name={`txtPrevHouseNumber_${preCount}`}
                        id={`txtPrevHouseNumber_${preCount}`}
                        className="form-control  hero-input"
                        dataId={`txtPrevHouseNumber_${preCount}`}
                        autoComplete="off"
                        readonly={false}
                        validation={validation({
                            required: "Please Enter Address Line 1",
                        })}
                        validationMsg={
                            validationMsg[`txtPrevHouseNumber_${preCount}`] &&
                            validationMsg[`txtPrevHouseNumber_${preCount}`].message
                        }
                        value={state.getDetails.line_1}
                        onChange={() => { clearErrors(`txtPrevHouseNumber_${preCount}`) }}
                    />
                    <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                    ></i>
                </div>

                <div className={`col-lg-12 form-group adj_width mb-1`}>
                    <AddressTextField
                        type="text"
                        placeholder="Address Line 2"
                        name={`txtPrevAddress3_${preCount}`}
                        id={`txtPrevAddress3_${preCount}`}
                        className="form-control  hero-input"
                        dataId={`txtPrevAddress3_${preCount}`}
                        autoComplete="off"
                        readonly={false}
                        value={state.getDetails.line_2}
                        validation={validation}
                    />
                    <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                    ></i>
                </div>

                <div className={`col-lg-12 form-group adj_width mb-1`}>
                    <AddressTextField
                        type="text"
                        placeholder="Street"
                        name={`txtPrevStreet_${preCount}`}
                        id={`txtPrevStreet_${preCount}`}
                        className="form-control  hero-input"
                        dataId={`txtPrevStreet_${preCount}`}
                        autoComplete="off"
                        readonly={false}
                        value={state.getDetails.line_3}
                        onChange={() => { clearErrors(`txtPrevStreet_${preCount}`) }}
                        validation={validation}
                    />
                    <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                    ></i>
                </div>

                <div className={`col-lg-12 form-group adj_width mb-1`}>
                    <AddressTextField
                        type="text"
                        placeholder="County"
                        name={`txtPrevCounty_${preCount}`}
                        id={`txtPrevCounty_${preCount}`}
                        className="form-control  hero-input"
                        dataId={`txtPrevCounty_${preCount}`}
                        autoComplete="off"
                        readonly={false}
                        value={state.getDetails.county}
                        validation={validation}

                    />
                    <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                    ></i>
                </div>

                <div className={`col-lg-12 form-group adj_width mb-1`}>
                    <AddressTextField
                        type="text"
                        placeholder="Town *"
                        name={`txtPrevTown_${preCount}`}
                        id={`txtPrevTown_${preCount}`}
                        className="form-control  hero-input"
                        dataId={`txtPrevTown_${preCount}`}
                        autoComplete="off"
                        readonly={false}
                        validation={validation({ required: "Please Enter Town" })}
                        validationMsg={
                            validationMsg[`txtPrevTown_${preCount}`] && validationMsg[`txtPrevTown_${preCount}`].message
                        }
                        value={state.getDetails.town}
                        onChange={() => { clearErrors(`txtPrevTown_${preCount}`) }}
                    />
                    <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                    ></i>
                </div>
                <div className={`col-lg-12 form-group adj_width mb-1`}>
                    <AddressTextField
                        type="text"
                        placeholder="Country *"
                        name={`txtPrevCountry_${preCount}`}
                        id={`txtPrevCountry_${preCount}`}
                        className="form-control  hero-input"
                        dataId={`txtPrevCountry_${preCount}`}
                        autoComplete="off"
                        readonly={false}
                        validation={validation({ required: "Please Enter Country" })}
                        validationMsg={
                            validationMsg[`txtPrevCountry_${preCount}`] && validationMsg[`txtPrevCountry_${preCount}`].message
                        }
                        value={state.getDetails.country}
                        onChange={() => { clearErrors(`txtPrevCountry_${preCount}`) }}
                    />
                    <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                    ></i>
                </div>
                {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
            </div>

        </>
    );
};
export default PreviousPostCode;
