import React, { useRef, useReducer } from "react";
import { BrowserView, MobileOnlyView, TabletView, deviceType, isIPad13 } from "react-device-detect";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../../UI/Button";
import ProgressBar from "./ProgressBar";
import sws_loa from "../../../../assets/pdf/sws_loa.pdf";
import sws_privacy_policy from "../../../../assets/pdf/sws_privacy_policy.pdf";
import sws_terms from "../../../../assets/pdf/sws_terms.pdf";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};


const Signature_Sygrv1 = (props) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const signPadRef = useRef();

  const handleClick = async (e) => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      // if (!isChecked) {
      //   return dispatch({ type: "privacyAgreed", payload: { value: true } });
      // }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        var signatureData = signPad.getCanvas().toDataURL("image/png");
        localStorage.setItem("signatureData", signatureData);
        triggerSlideGtm();
        props.slideChange(e);
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  const triggerSlideGtm = () => {
    let gtmData = ['signature'];
    for (const item of gtmData) {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: item,
        answer: '',
      });
    }

  }
  return (
    <>
      <div className={`form-view animate__animated animate__fadeInUp animate__delay-.5s ${props.className}`} id="step-6">
        <ProgressBar step={props.step} load={props.loadPercent} />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s sign_here">
          <label>Your E-Signature <em>*</em><span>Please use your finger or mouse to draw your signature in the box below.</span>
          </label>
          <div id="signature-pad">
            <div className="sign-canva-div">
              {(() => {
                if (deviceType === "mobile") {
                  return (
                    <MobileOnlyView className="mob-canvas">
                      <SignatureCanvas
                        canvasProps={{
                          id: "myID",
                          width: 500,
                          height: 265,
                          className:
                            "signature-pad anim_bt d-lg-block mob-canvas",
                        }}
                        ref={signPadRef}
                        onEnd={() => {
                          if (!signPadRef.current.isEmpty()) {
                            dispatch({
                              type: "signPadChange",
                              payload: { value: false },
                            });
                            dispatch({
                              type: "validSignature",
                              payload: { value: false },
                            });
                            // document
                            //   .getElementById("acceptterms")
                            //   .classList.add("anim_ylw");
                          }
                        }}
                      />
                    </MobileOnlyView>
                  );
                } else if (deviceType === "tablet" || isIPad13) {
                  return (
                    <TabletView className="tab-canvas">
                      <SignatureCanvas
                        canvasProps={{
                          id: "myID",
                          width: 600,
                          height: 300,
                          className:
                            "signature-pad anim_bt d-lg-block tab-canvas",
                        }}
                        ref={signPadRef}
                        onEnd={() => {
                          if (!signPadRef.current.isEmpty()) {
                            dispatch({
                              type: "signPadChange",
                              payload: { value: false },
                            });
                            dispatch({
                              type: "validSignature",
                              payload: { value: false },
                            });
                            // document
                            //   .getElementById("acceptterms")
                            //   .classList.add("anim_ylw");
                          }
                        }}
                      />
                    </TabletView>
                  );
                } else {
                  return (
                    <BrowserView className="web-canvas">
                      <SignatureCanvas
                        canvasProps={{
                          id: "myID",
                          width: 985,
                          height: 265,
                          className:
                            "signature-pad anim_bt d-lg-block web-canvas",
                        }}
                        ref={signPadRef}
                        onEnd={() => {
                          if (!signPadRef.current.isEmpty()) {
                            dispatch({
                              type: "signPadChange",
                              payload: { value: false },
                            });
                            dispatch({
                              type: "validSignature",
                              payload: { value: false },
                            });
                            // document
                            //   .getElementById("acceptterms")
                            //   .classList.add("anim_ylw");
                          }
                        }}
                      />
                    </BrowserView>
                  );
                }
              })()}
            </div>
          </div>
          {state.isAgreed && (
            <div className="sign_msg col-12 text-center">
              Please tick to show your authorisation for us to
              proceed.
            </div>
          )}
          {state.isCanvasEmpty && (
            <div className="sign_msg col-12 text-center sign_msg error" id="signatures_required">
              Signature is required!
            </div>
          )}
          {state.isValid && (
            <div className="sign_msg col-12 text-center sign_msg error" id="signatures_valid">
              Draw valid signature!
            </div>
          )}
          <label id="error" className="error d-none" > Please select an option to proceed </label>
          <Button
            type="button"
            name={`clear`}
            id={`clear`}
            className=""
            buttonText="Clear"
            onClick={clearSign}
          />
          <p className="pt-3 mb-2 text-white">By clicking 'Submit', you confirm:</p>
          <ul className="text-white">
            <li>You have read and agreed to our <a href={sws_privacy_policy} target="_blank">Privacy Policy</a>;</li>
            <li>You consent to your e-signature being applied to our <a href={sws_loa} target="_blank">Letter of Authority</a> – the document we send to your financial firms telling them we have your authority to represent you;</li>
            <li>You consent to Get Your Refund contacting you by email, SMS, telephone and WhatsApp to complete your claim enquiry;</li>
            <li>You have read and agreed to our <a href={sws_terms} target="_blank">Terms and Conditions</a>, and consent to your e-signature being applied to them. In accordance with our Terms and Conditions, if your claim is successful, our fee is 25% plus VAT of any compensation amount; and</li>
            <li>You have not previously submitted a claim (whether personally or via any other third party) regarding undisclosed or discretionary commissions to any of the lenders you are now asking us to investigate.</li>
          </ul>
        </div>



        <div className="pre_cs_btn_dialNum pt-4 pb-0 position-relative d-none d-md-block">
          <span className="main-heading-group">
            <Button
              type="button"
              name={`signature`}
              id={`signature`}
              className="main-heading-button submit button--anim custom_clickBtn btn-vp"
              buttonText="Submit"
              onClick={handleClick}
            />
          </span>
        </div>
        <div className="form-sub_btn d-none d-md-block">
          <Button
            type="button"
            name={`signature`}
            id={`back_signature`}
            className="msf-nav-button next button color-1"
            buttonText="Previous"
            onClick={props.backClick}
          />
        </div>


        <div className="lenderbutton pt-4 d-block d-md-none" style={{ display: "block" }}>
          <div className="row">
            <div className="col-3 pe-0">

              <Button
                type="button"
                name={`signature`}
                id={`back_signature`}
                className="msf-nav-button previous_btnSign button color-1"
                buttonText="Previous"
                onClick={props.backClick}
              />
            </div>

            <div className="col-9">
              <div className="pre_cs_btn_dialNum pb-0 position-relative">
                <span className="main-heading-group">
                  <Button
                    type="button"
                    name={`signature`}
                    id={`signature`}
                    className="main-heading-button submit button--anim custom_clickBtn btn-vp"
                    buttonText="Submit"
                    onClick={handleClick}
                  />

                </span>
              </div>
            </div>
          </div>
        </div>




      </div>
    </>
  )
}

export default Signature_Sygrv1;